import { useCallback, useEffect, useState } from "react";

type UpdaterFn<T> = (currentState: T) => T;

type Result<T> = [
  state: T | undefined,
  wrappedSetState: (update: T | UpdaterFn<T>) => void,
  clear: () => void
];

export default function useSessionStorage<T>(
  key: string,
  initialValue?: T
): Result<T> {
  const [state, setState] = useState<T | undefined>(() => {
    try {
      const item = window.sessionStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);

      return initialValue;
    }
  });

  const wrappedSetState = useCallback(
    (update) => {
      try {
        setState((currentState) => {
          const nextState =
            typeof update === "function" ? update(currentState) : update;

          window.sessionStorage.setItem(key, JSON.stringify(nextState));
          return nextState;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key]
  );

  const onStorageEvent = useCallback(
    (e: StorageEvent) => {
      if (e.key === key && e.newValue != e.oldValue) {
        let nextState = null;
        try {
          nextState = JSON.parse(e.newValue || "null");
        } catch (err) {
          console.error("Error parsing value from sessionStorage event", err);
        }
        setState(nextState);
      }
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageEvent);
    return () => window.removeEventListener("storage", onStorageEvent);
  }, [onStorageEvent]);

  const clear = useCallback(() => {
    window.sessionStorage.removeItem(key);
    setState(undefined);
  }, [key]);

  return [state, wrappedSetState, clear];
}
