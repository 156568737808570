import { noop } from "lodash";
import React, { FC } from "react";

import { Container, PullButtonLeft, PullButtonRight } from "./styles";
import type { Props } from "./types";

export const Button: FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      onClick = noop,
      rounded = false,
      type = "button",
      variant = "primary",
      size = "md",
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <Container
        onClick={disabled ? noop : onClick}
        ref={ref}
        $rounded={rounded}
        type={props.as === "a" ? undefined : type}
        $variant={variant}
        $size={size}
        disabled={disabled}
        {...props}
      >
        {children}
      </Container>
    );
  }
);

export default Button;
export { PullButtonLeft, PullButtonRight };
