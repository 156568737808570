import type { ReactNode } from "react";
import { css } from "styled-components";

type Props = {
  /**
   * Applies this factor to all margins
   */
  all?: number;
  /**
   * Applies this factor to the bottom margin
   */
  bottom?: number;
  /**
   * The children of the Spacer component
   */
  children: ReactNode;
  /**
   * Applies this factor to the left and right margins
   */
  horz?: number;
  /**
   * Applies this factor to the left margin
   */
  left?: number;
  /**
   * Applies this factor to the right margin
   */
  right?: number;
  /**
   * Applies this factor to the top margin
   */
  top?: number;
  /**
   * Applies this factor to the top and bottom margins
   */
  vert?: number;
};

const toRem = (num: number) => `${num}rem`;

/**
 * The purpose of the Spacer component is to be a standardized way of applying
 * margins to reusable components through composition.
 *
 * Margins break encapsulation of reusable components because they affect the
 * layout of other components. You can read more about this here:
 * https://mxstbr.com/thoughts/margin/
 *
 * For example, a "H3" component that exports margins can be rather troublesome
 * to use when it's required inside of a Card and the top & bottom margins
 * should be different. Rather than export the `H3` component with a margin,
 * it can be composed with Spacer to achieve that same result:
 *
 * // this will apply a `1rem` margin to the top and bottom
 * <Spacer vert={1}>
 *  <H3>Company Name</H3>
 * </Spacer>
 */
export default function Spacer({
  all = 0,
  bottom = 0,
  children,
  horz = 0,
  left = 0,
  right = 0,
  top = 0,
  vert = 0,
}: Props) {
  const margins = {
    ...(all && { margin: toRem(all) }),
    ...(horz && { marginLeft: toRem(horz), marginRight: toRem(horz) }),
    ...(vert && { marginTop: toRem(vert), marginBottom: toRem(vert) }),
    ...(top && { marginTop: toRem(top) }),
    ...(right && { marginRight: toRem(right) }),
    ...(bottom && { marginBottom: toRem(bottom) }),
    ...(left && { marginLeft: toRem(left) }),
  };

  return <div css={margins}>{children}</div>;
}

export function EmptySpacer({
  height,
  width,
  unit = "px",
}: {
  height?: number;
  width?: number;
  unit?: "px" | "rem";
}) {
  return (
    <div
      css={`
        ${height &&
        css`
          height: ${height}${unit};
        `}

        ${width &&
        css`
          width: ${width}${unit};
        `}
      `}
    ></div>
  );
}
