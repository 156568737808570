import styled, { css } from "styled-components";

import type { Props } from "./types";

const BORDER_WIDTH = "1px";

export const sharedSolidButtonStyles = css`
  border-radius: 0.25rem;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  outline: none;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 2rem;
`;

const setBackgroundAndBorder = (bgColor: string, fgColor?: string) => css`
  background-color: ${bgColor};
  border: ${BORDER_WIDTH} solid ${fgColor || bgColor};
`;

export const Container = styled.button<{
  $rounded: boolean;
  $size: NonNullable<Props["size"]>;
  $variant: NonNullable<Props["variant"]>;
}>`
  ${sharedSolidButtonStyles}

  transition: background-color 0.1s linear;

  &:not(:disabled),
  &:not([disabled]) {
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.speech};
  }

  ${({ $variant, theme, $size }) => {
    let sizeStyles;
    switch ($size) {
      case "sm":
        sizeStyles = css`
          font-size: 0.75rem;
        `;
        break;
      case "md":
        sizeStyles = css`
          font-size: 0.9375rem;
          padding: calc(0.625rem - ${BORDER_WIDTH})
            calc(0.875rem - ${BORDER_WIDTH});
        `;
        break;
      case "lg":
        sizeStyles = css`
          font-size: 1.25rem;
          padding: calc(1rem - ${BORDER_WIDTH}) calc(1rem - ${BORDER_WIDTH});
        `;
    }

    switch ($variant) {
      case "link":
        return css`
          ${sizeStyles}
          background-color: transparent;
          border-color: transparent;
          color: ${theme.colors.oil};
          &:disabled,
          &[disabled] {
            color: ${theme.colors.moonMedium};
          }

          &:hover:not(:disabled),
          &:hover:not([disabled]) {
            text-decoration: underline;
          }
        `;

      case "icon":
        return css`
          ${sizeStyles}
          background-color: transparent;
          border-color: transparent;
          border-radius: 0.25rem;
          color: ${theme.colors.oil};
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.75rem;
          height: 1.75rem;
          padding: 0.125rem;
          transition: color 0.2s ease, background-color 0.2s ease;

          &:hover {
            background-color: ${theme.colors.moonMedium};
          }

          &:disabled,
          &[disabled] {
            color: ${theme.colors.moonDark}88;

            &:hover {
              background-color: transparent;
            }
          }
        `;

      case "speech":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.speech)}
          color: ${theme.colors.white};

          &:disabled,
          &[disabled] {
            ${setBackgroundAndBorder(theme.colors.moonDark)}
          }
        `;

      case "secondary":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.moonLight)}
          color: ${theme.colors.oil};
        `;

      case "danger":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.cinnabar)}
          color: ${theme.colors.moonLight};
          &:focus,
          &:active {
            border-color: ${theme.colors.cinnabar};
          }
          &:disabled,
          &[disabled] {
            ${setBackgroundAndBorder(theme.colors.moonDark)}
          }
        `;

      case "danger-dark":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.badTomato)}
    color: ${theme.colors.moonLight};
          &:focus,
          &:active {
            border-color: ${theme.colors.badTomato};
          }
        `;

      case "hollow-primary":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.oil, theme.colors.white)}

          font-weight: 500;
          padding: 0.5rem 1rem;
          color: ${theme.colors.white};

          &:focus,
          &:active {
            border-color: ${theme.colors.white};
          }
        `;

      case "hollow-primary-on-light":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.moonLight, theme.colors.oil)}

          font-weight: 500;
          padding: 0.5rem 1rem;
          color: ${theme.colors.oil};

          &:hover {
            background-color: ${theme.colors.moon};
          }

          &:focus,
          &:active {
            border-color: ${theme.colors.oil};
          }

          &:disabled,
          &[disabled] {
            border-color: ${theme.colors.moonMedium};
          }
        `;

      case "hollow-speech":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(
            theme.colors.speechLight,
            theme.colors.speech
          )}

          font-weight: 500;
          padding: 0.5rem 1rem;
          color: ${theme.colors.speech};

          &:hover {
            background-color: ${theme.colors.speech10};
          }

          &:focus,
          &:active {
            border-color: ${theme.colors.oil};
          }

          &:disabled,
          &[disabled] {
            border-color: ${theme.colors.moonMedium};
          }
        `;

      case "primary":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles}
          ${setBackgroundAndBorder(theme.colors.oil)}
          color: ${theme.colors.white};

          &:focus,
          &:active {
            background-color: ${theme.colors.oil}D0;
          }

          &:disabled,
          &[disabled] {
            ${setBackgroundAndBorder(theme.colors.moonDark)}
          }
        `;

      case "borderless-murple":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles};
          ${setBackgroundAndBorder("transparent")};
          background-color: "none";
          color: ${theme.colors.speech};
          &:active {
            border-color: transparent;
          }
          &:disabled,
          &[disabled] {
            color: ${theme.colors.darkSilver};
          }
        `;

      case "borderless-icon":
        return css`
          ${sizeStyles};
          ${sharedSolidButtonStyles};
          ${setBackgroundAndBorder("transparent")};
          background-color: "none";
          color: ${theme.colors.speech};
          width: 1.75rem;
          height: 1.75rem;
          padding: 0.125rem;
          &:active {
            border-color: transparent;
          }
          &:focus {
            color: ${theme.colors.speech75};
          }
          &:disabled,
          &[disabled] {
            color: ${theme.colors.darkSilver};
          }
        `;

      default:
        throw new Error(
          `Button does not have an implementation for the variant: ${$variant}`
        );
    }
  }};

  ${({ $rounded }) => {
    if (!$rounded) return;

    return css`
      border-radius: 43px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    `;
  }}
`;

export const PullButtonLeft = styled.div`
  margin-left: calc(-1 * (0.875rem - ${BORDER_WIDTH}));
`;

export const PullButtonRight = styled.div`
  margin-right: calc(-1 * (0.875rem - ${BORDER_WIDTH}));
`;
