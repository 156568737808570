import { useRouter } from "next/router";
import { useEffect } from "react";

import { useUserContext } from "~/contexts/UserContext";

import useSessionStorage from "./useSessionStorage";

/**
 * This hook returns and stores the workspace slug (initially set from the URL
 * or the user's list of workspaces) in session storage, and updates it when
 * the workspace slug changes.
 *
 * For historical context: we used to exclusively read the slug from the URL,
 * and thus all URLs had to include the workspace slug. This hook can now read
 * from session storage, which allows us to omit the workspace slug from some
 * URLs while remembering the user's last workspace (URLs which encode
 * information about the page itself in the workspace slug, e.g.
 * `/[workspace]/groups`, should continue to include the slug for now).
 */
export default function useWorkspaceSlug() {
  const { query } = useRouter();
  const { user } = useUserContext();
  const { workspace } = query;
  const slug = workspace || user?.workspaces?.[0]?._id || "";

  const [storedWorkspaceSlug, setStoredWorkspaceSlug] = useSessionStorage(
    "workspaceSlug",
    slug
  );

  useEffect(() => {
    if (slug) {
      setStoredWorkspaceSlug(slug);
    }
  }, [slug, setStoredWorkspaceSlug]);

  return storedWorkspaceSlug as string;
}
